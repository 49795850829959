<template>
  <Dialog
      v-model:visible="dialogActive" class="dialog-file-preview" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}" :closable="false" :draggable="false" modal @hide="closeDialog">
    <div class="flex flex-column w-full">
      <div class="field">
        <Label>Montant réglé</Label>
        <InputNumber v-model="v$.paidPrice.$model" :class="{'p-invalid':v$.paidPrice.$errors.length}"
                     locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
      </div>
      <div v-if="v$.paidPrice.$errors.length" class="mb-2 w-full">
        <InlineMessage v-for="(error, index) of v$.paidPrice.$errors" :key="index" class="w-full">{{error.$message}}</InlineMessage>
      </div>
      <div class="field">
        <Label>Date du règlement</Label>
        <Calendar id="invoiceDate" v-model="v$.paidDate.$model" :class="{'p-invalid':v$.paidDate.$errors.length}"
                  :showIcon="true" date-format="dd/mm/yy"/>
      </div>
      <div v-if="v$.paidDate.$errors.length" class="mb-2 justify-content-center w-full">
        <InlineMessage v-for="(error, index) of v$.paidDate.$errors" :key="index" class="w-full">{{error.$message}}</InlineMessage>
      </div>
      <div class="field">
        <Label>Commentaire</Label>
        <InputText v-model="comment"/>
      </div>
<!--      <div class="field">-->
<!--        <Label>À déduire</Label>-->
<!--        <InputNumber v-model="deduceAmount" locale="fr-FR" mode="currency" currency="EUR" />-->
<!--      </div>-->
    </div>
    <template #footer>
      <Button label="Annuler" class="p-button-outlined mr-2" @click="paidCancel"/>
      <Button label="Valider le règlement" @click="paidValidate"/>
    </template>
  </Dialog>
</template>

<script>
import {helpers, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  emits: ['closeDialog', 'paidValidate', 'cancelPaidValidate'],
  name: "DialogPayInvoice",
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    invoice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialogActive: false,

      paidPrice: 0,
      paidDate: null,
      comment: null,
      // deduceAmount: null
    }
  },
  computed: {},
  watch: {
    isActive (value) {
      this.dialogActive = value
    }
  },
  validations() {
    return {
      paidPrice: {
        required: helpers.withMessage('Montant réglé requis', required),
      },
      paidDate: {
        required: helpers.withMessage('Date de règlement requise', required),
      },
    }
  },
  created () {
    this.initFields()
    this.dialogActive = this.isActive
  },
  methods: {
    initFields () {
      if (this.invoice.paid) {
        this.paidPrice = this.invoice.ttc
      } else {
        this.paidPrice = 0
      }
      this.paidDate = new Date()
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    paidCancel () {
      this.$emit('cancelPaidValidate', this.invoice)
    },
    async paidValidate () {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      const payload = {
        ...this.invoice,
        paidPrice: this.paidPrice,
        paidDate: this.paidDate,
        comment: this.comment,
        // deduceAmount: this.deduceAmount
      }
      this.$emit('paidValidate', payload)
    }
  }
}
</script>

<style scoped>

</style>
