<template>
  <div class="flex-container non-scrollable">
    <page-title value="Facture règlements"></page-title>
    <div class="non-scrollable">
      <DataTable ref="dt" :value="invoices" :paginator="true" class="p-datatable-sm editable-cells-table" :rows="constants.defaultRowNumber" selection-mode="single"
                 dataKey="id" :rowHover="false" v-model:filters="filters" filterDisplay="menu" :loading="loading"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
                 :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.data')})"
                 scrollable scrollHeight="flex" @row-click="clickInvoice">
        <template #header>
          <div class="p-d-flex flex justify-content-between">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
            </span>
            <div v-if="hasAccess($route.name, true)" class="p-d-flex flex justify-content-end">
              <Button class="mr-3" :disabled="invoices.length === 0" :class="{ 'disabled-button': invoices.length === 0 }" :loading="downloadPdfLoading" icon="pi pi-download" :label="$t('exportPdf')" @click="downloadPdf" />
              <Button :disabled="invoices.length === 0" :class="{ 'disabled-button': invoices.length === 0 }" :loading="exportSheetLoading" icon="pi pi-external-link" :label="$t('export')" @click="createSheet" />
            </div>
          </div>
        </template>
        <template #empty>
          {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
        </template>
        <template #loading>
          {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
        </template>
        <Column header="PDF">
          <template #body="{data}">
            <Button :disabled="!data.pdf" :class="{ 'disabled-button': !data.pdf }" rounded
                    icon="pi pi-download" @click="downloadInvoicePdf(data)" />
          </template>
        </Column>
        <Column field="invoice_number" :header="$t('numeroFacture').toUpperCase()" sortable sortField="invoice_number" class="non-clickable-column"/>
        <Column field="independent.last_name" :header="$t('lastname').toUpperCase()" sortable sortField="independent.last_name"/>
        <Column field="independent.first_name" :header="$t('firstname').toUpperCase()" sortable sortField="independent.first_name"/>
        <Column field="year" :header="$t('year').toUpperCase()" sortable filterField="year" sortField="year" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('year')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="month" :header="$t('month').toUpperCase()" sortable filterField="month" sortField="month" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data}">
            <span class="uppercase">{{data.month}}</span>
          </template>
          <template #filter="{filterModel, filterCallback}" >
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('month') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('month')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="paid" :header="$t('paid').toUpperCase()" :sortable="false" filterField="paid" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{ data, field }">
            <Checkbox v-model="data[field]" :binary="true" :class="[{'checkbox-warning': data[field] && data.paid_amount !== data.ttc}]"
             @change.stop="payInvoice(data)"/>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('paid') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('paid')" option-value="value" :optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #value="value">{{ getFilterValue(value) }}</template>
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value ? 'Oui' : 'Non'}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="paymentDelay" :header="$t('delaiReglement').toUpperCase()" sortable filterField="paymentDelay" sortField="paymentDelay" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data}">
            <span>{{data.paymentDelay}}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('delai') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('paymentDelay')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="settlementPeriod" :header="$t('echeance').toUpperCase()" sortable filterField="settlementPeriod" sortField="settlementPeriod" :showFilterMatchModes="false" :show-apply-button="false">
          <template #body="{data, field}">
            <span>{{ data[field]?.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <Dropdown v-model="filterModel.matchMode" :options="FilterMatchModeCustomOptions()"
                      option-value="value" option-label="label" class="mb-2" @update:model-value="filterCallback()" />
            <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" :manual-input="false"
                      :showOnFocus="false" show-icon @date-select="filterCallback()"/>
          </template>
        </Column>
        <Column :header="$t('retard').toUpperCase()">
          <template #body="{data}">
            {{ compareDate(data) }}
          </template>
        </Column>
        <Column field="mission.clientContract.name" :header="$t('client').toUpperCase()" sortable filterField="mission.clientContract.name" sortField="mission.clientContract.name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('client') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('mission.clientContract.name')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="bank.libelle" :header="$t('bank').toUpperCase()" sortable filterField="bank.libelle" sortField="bank.libelle" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('bank') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('bank.libelle')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="ttc" :header="$t('ttc').toUpperCase()" sortable filterField="ttc" sortField="ttc" :showFilterMatchModes="false">
          <template #body="{data, field}">
            {{ getEuroFormatFromEuro(data[field]) }}
          </template>
          <template #filter="{filterModel}">
            <Slider v-model="filterModel.value" range :min="minTtc" :max="maxTtc" class="m-3"></Slider>
            <div class="flex align-items-center justify-content-between px-2">
              <span>{{filterModel.value ? filterModel.value[0] : minTtc}}</span>
              <span>{{filterModel.value ? filterModel.value[1] : maxTtc}}</span>
            </div>
          </template>
        </Column>
        <Column>
          <template #header>
            <Button :icon="deployPaidDetails ? 'pi pi-angle-double-left' : 'pi pi-angle-double-right'" size="small" rounded aria-label="Deploy" @click="deployPaidDetails = !deployPaidDetails" />
          </template>
        </Column>
        <Column v-if="deployPaidDetails" field="paid_amount" header="MONTANT RÉGLÉ" sortField="paid_amount">
          <template #body="{data, field}">
            <span :class="[{'text-orange-500': data.paid && data[field] !== data.ttc}]">{{ getEuroFormatFromEuro(data[field]) }}</span>
          </template>
          <template #editor="{data, field}">
            <InputNumber v-model="data[field]" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
          </template>
        </Column>
        <Column v-if="deployPaidDetails" field="payment_date" header="DATE DE RÉGLEMENT" sortField="payment_date">
          <template #body="{data, field}">
            <span> {{ !data[field] ? '' : data[field]?.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</span>
          </template>
          <template #editor="{data, field}">
            <Calendar id="invoiceDate" :showIcon="true" v-model="data[field]" date-format="dd/mm/yy" autofocus></Calendar>
          </template>
        </Column>
        <Column v-if="deployPaidDetails" field="comment" :header="$t('comment').toUpperCase()" sortField="comment">
          <template #editor="{data, field}">
            <InputText v-model="data[field]" autofocus></InputText>
          </template>
        </Column>
        <Column v-if="deployPaidDetails" field="sending_date" header="DATE D'ENVOI">
          <template #body="{data, field}">
            <span> {{ !data[field] ? '' : data[field]?.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</span>
          </template>
        </Column>
        <Column v-if="deployPaidDetails" field="last_notification_date" header="RELANCE">
          <template #body="{data, field}">
            <div class="flex flex-column align-items-center gap-2">
              <Button v-if="isReportableInvoice(data)" :loading="reportLoading" label="RELANCE" class="p-button-rounded" size="small" @click="reportInvoice(data)" />
              <div v-if="data[field] !== null" class="flex flex-row gap-1">
                <span class="text-xs">{{ `Dernière le ${new Date(data[field]).toLocaleDateString('fr-FR')} ${data.last_notification_scheduled ? 'par relance automatique' : ''}` }}</span>
              </div>
            </div>
          </template>
        </Column>
<!--        todo: waiting for details about this new column-->
<!--        <Column v-if="deployPaidDetails" header="ENVOYÉE">-->
<!--          <template #body="{ data }">-->
<!--            <i v-if="data." class="pi pi-check"/>-->
<!--          </template>-->
<!--        </Column>-->
<!--        <Column field="deduce_amount" :header="$t('deduire').toUpperCase()" sortField="deduce_amount">-->
<!--          <template #body="{data, field}">-->
<!--            {{ getEuroFormatFromEuro(data[field]) }}-->
<!--          </template>-->
<!--          <template #editor="{data, field}">-->
<!--            <InputNumber v-model="data[field]" locale="fr-FR" mode="currency" currency="EUR" />-->
<!--          </template>-->
<!--        </Column>-->
      </DataTable>
    </div>
    <DialogPayInvoice v-if="paidInvoice" :invoice="paidInvoice" :is-active="paidInvoiceDialog"
                      @close-dialog="closePaidInvoiceDialog" @cancel-paid-validate="cancelPaidInvoice"
                      @paidValidate="savePaidInvoice"/>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import {mapGetters, mapState} from 'vuex'
import { getEuroFormatFromEuro } from '@/utils/Money'
import Alert from '@/utils/Alert'
import SheetGenerator from "@/utils/SheetGenerator";
import {hasAccess} from "@/utils/Misc";
import {FilterMatchModeCustom, FilterMatchModeCustomOptions} from "@/utils/FiltersRegistration";
import PageTitle from "@/components/PageTitle.vue";
import DialogPayInvoice from "@/components/DialogPayInvoice.vue";
const FileSaver = require('file-saver')

export default {
  name: "FactureReglements",
  components: {DialogPayInvoice, PageTitle},
  data() {
    return {
      hasAccess: hasAccess,
      minTtc: 0,
      maxTtc: 999999,
      exportSheetLoading: false,
      downloadPdfLoading: false,
      customers: null,
      selectedCustomers: null,
      filters: {
        'global': {value: null, matchMode: null},
        // 'invoice_number': {value: null, matchMode: FilterMatchMode.IN},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'year': {value: null, matchMode: FilterMatchMode.IN},
        'month': {value: null, matchMode: FilterMatchMode.IN},
        'service': {value: null, matchMode: FilterMatchMode.IN},
        'paid': {value: null, matchMode: FilterMatchMode.IN},
        'paymentDelay': {value: null, matchMode: FilterMatchMode.IN},
        'settlementPeriod': {value: null, matchMode: FilterMatchModeCustom.DATE_BEFORE_INCLUDED},
        'mission.clientContract.name': {value: null, matchMode: FilterMatchMode.IN},
        'bank.libelle': {value: null, matchMode: FilterMatchMode.IN},
        'ttc': {value: null, matchMode: FilterMatchMode.BETWEEN},
      },
      loading: true,
      reportLoading: false,

      deployPaidDetails: false,
      paidInvoiceDialog: false,
      paidInvoice: null,

      editingRows: []
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
    ...mapGetters({
      invoices: 'invoice/getInvoices'
    })
  },
  mounted() {
    this.loading = false;
  },
  created () {
    this.loadInvoices()
  },
  methods: {
    FilterMatchModeCustomOptions() {
      return FilterMatchModeCustomOptions
    },
    async downloadInvoicePdf (invoice) {
      const file = await this.$store.dispatch('file/downloadFile', {id: invoice.pdf })
      const blob = new Blob([new Uint8Array(file.data.data)], { type: file.mimetype })
      FileSaver.saveAs(blob, `${invoice.invoice_number}.pdf`)
    },
    async createSheet () {
      this.exportSheetLoading = true
      // get directly the filtered and sorted data in the dataTable
      const ids = await this.$refs.dt.processedData.map(invoice => invoice.id)
      SheetGenerator({ ids, exportAll: false }, '/invoice/export-sheet', this)
          .then(() => {
            this.exportSheetLoading = false
          })
          .catch(() => {
            this.exportSheetLoading = false
          })
    },
    async downloadPdf () {
      this.downloadPdfLoading = true
      // get directly the filtered and sorted data in the dataTable
      const ids = await this.$refs.dt.processedData.map(invoice => invoice.id)
      let res = await this.$store.dispatch('invoice/downloadInvoices', ids)
      const blob = new Blob([new Uint8Array(res.data.data)], { type: 'application/zip' })
      const link = document.createElement('a')
      link.download = 'Factures.zip'
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
      this.downloadPdfLoading = false
    },
    getEuroFormatFromEuro (price, withUnit) {
      return getEuroFormatFromEuro(price, withUnit)
    },
    loadInvoices () {
      this.$store.dispatch('invoice/getAllInvoices', {status: 'invoiced'}).then(() => {
        this.minTtc = Math.min(...this.invoices.map((i) => i.ttc))
        this.maxTtc = Math.max(...this.invoices.map((i) => i.ttc))
      })
    },
    compareDate (invoice) {
      let date = new Date()
      if (date > invoice.settlementPeriod) {
        const diffTime = Math.abs(date - invoice.settlementPeriod);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays + ' jours'
      }
      return ''
    },
    getFilterValue (value) {
      if (value.value && value.value.length) {
        if (value.value.length === 1) return value.value[0] ? 'Oui' : 'Non'
        let string = ''
        string += value.value[0] ? 'Oui' : 'Non'
        string += ', '
        string += value.value[1] ? 'Oui' : 'Non'
        return string
      } else {
        return value.placeholder
      }
    },
    commonFilter (field) {
      let array = this.invoices.map((s) => {
        let fields = field.split('.')
        let v = s
        fields.forEach((f) => {
          v = v[f]
        })
        return v
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item || Math.abs(fa.value - item) === 0)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    closePaidInvoiceDialog () {
      this.paidInvoice = null
      this.paidInvoiceDialog = false
    },
    payInvoice (invoice) {
      this.paidInvoice = invoice
      this.paidInvoiceDialog = true
    },
    cancelPaidInvoice (invoice) {
      this.$store.dispatch('invoice/updateInvoice', {
        id: invoice.id,
        paid: !invoice.paid
      })
      this.closePaidInvoiceDialog()
    },
    async savePaidInvoice (invoice) {
      if (invoice.paid) {
        invoice.paid_amount = invoice.ttc
      }
      let invoiceToSave = {
        id: invoice.id,
        paid: invoice.paid,
        paid_amount: invoice.paidPrice,
        deduce_amount: invoice.deduceAmount,
        payment_date: invoice.paidDate,
        comment: invoice.comment
      }

      this.$store.state.misc.loading = true

      await this.$store.dispatch('invoice/saveValidatedInvoice', invoiceToSave)
      this.$store.state.misc.loading = false
      this.closePaidInvoiceDialog()
      Alert.successMessage(this, 'saveInvoice')
    },
    async clickInvoice(event) {
      const invoice = event.data
      this.$store.state.misc.loading = true

      this.$router.push({name: 'FactureReglementsId', params: { id: invoice.id }})
    },
    isReportableInvoice (invoice) {
      if (!invoice) return false
      return invoice.status === this.constants.INVOICE_STATUS.INVOICED &&
            (!invoice.paid || (invoice.paid && invoice.paid_amount < invoice.ttc)) &&
            invoice?.mission?.billingContact?.email
    },
    async reportInvoice(invoice) {
      if (!invoice) return null
      if (invoice.last_notification_date !== null) {
        this.$confirm.require({
          header: 'Confirmation Relance',
          message: `Cette facture à déjà été relancée le ${new Date(invoice.last_notification_date).toLocaleDateString('fr-FR')}, souhaitez-vous quand même la relancer ?
           Les factures en souffrance sont relancées automatiquement tout les 5 jours`,
          acceptLabel: 'Continuer',
          rejectLabel: 'Annuler',
          accept: async () => {
            this.reportLoading = true
            await this.$store.dispatch('invoice/reportInvoice', { id: invoice.id })
            Alert.successMessage(this, 'reportDone')
            this.reportLoading = false
            this.$confirm.close()
          },
        })
      } else {
        this.reportLoading = true
        await this.$store.dispatch('invoice/reportInvoice', { id: invoice.id })
        Alert.successMessage(this, 'reportDone')
        this.reportLoading = false
      }
    }
  }
}
</script>

<style>
.checkbox-warning.p-checkbox .p-checkbox-box.p-highlight {
  border-color: orange;
  background: orange;
}
.checkbox-warning.p-checkbox .p-checkbox-box.p-highlight:hover {
  border-color: #dd9511;
  background: #dd9511;
}
</style>

<style scoped>
.p-datatable {
  font-size: 0.85rem;
}
</style>
